<template>
  <section id="kona-upload" class="kona-upload">
    <MediaPreview ref="mediaPreview" v-if="file && allowPreview" :file="file" />
    <MediaPreview ref="mediaPreview" v-if="!file && allowPreview && savedFile" :savedFile="savedFile" />

    <div class="kona-upload__input  w-3/4" @click="$refs.file.click()">
      <input
        class="hidden"
        type="file"
        ref="file"
        @change="handleFile($event)"
        :accept="accept"
      />

      <vs-input
        v-model="fileName"
        icon="icon-upload"
        icon-pack="feather"
        :icon-no-border="true"
        disabled
        class="flex-1 input-message user-says"
        required
        :placeholder="
          placeholder
            ? placeholder
            : lang.botMaker.botEditor.messageType.media.description[
                languageSelected
              ]
        "
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MAX_FILE_SIZE } from '@/constants/constants'

export default {
  name: 'KonaUpload',
  components: {
    MediaPreview: () => import('./MediaPreview.vue')
  },
  props: {
    allowPreview: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    placeholder: {
      type: String,
      default: ''
    },
    defaultFile: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      file: null,
      fileName: null,
      savedFile: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    validateFile(event) {
      const fileSize = event.target.files[0].size

      const valid = fileSize <= MAX_FILE_SIZE

      if (!valid) {
        this.$vs.notify({
          text:
            this.lang.botMaker.botEditor.messageType.media.errorMaxSize[
              this.languageSelected
            ] +
            MAX_FILE_SIZE / 1024 / 1024 +
            ' MB.',
          color: 'warning'
        })
      }

      return valid
    },
    handleFile(event) {
      const validFile = this.validateFile(event)

      if (validFile) {
        this.file = event.target.files[0]

        if (this.file) {
          this.fileName = this.file.name
          this.$emit('onChange', this.file)
        }
      }
    },
    reset() {
      this.file = null
      this.fileName = null
    }
  },
  mounted() {
    this.savedFile = this.defaultFile
  }
}
</script>

<style lang="scss">
.kona-upload {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  &__input {
    position: relative;
    cursor: pointer;
    width: 100%;
    min-width: 200px;

    .feather-icon {
      position: absolute !important;
      top: 5px !important;
    }
  }
}
</style>
